import request from '@utils/fetch'
// 信息维护

// // 会员管理列表
// export function bxUser(data) {
//   return request({
//     url: '/bxUser/bxUser',
//     method: 'post',
//     data
//   })
// }

// // 会员管理列表 用户解绑
// export function bxUserUnbinding(data) {
//   return request({
//     url: '/bxUser/bxUserUnbinding',
//     method: 'post',
//     data
//   })
// }


// // 医生管理列表 
// export function bxDockor(data) {
//   return request({
//     url: '/bxDockor/bxDockor',
//     method: 'post',
//     data
//   })
// }


// // 医生编辑 
// export function bxDockorUpdate(data) {
//   return request({
//     url: '/bxDockor/bxDockorUpdate',
//     method: 'post',
//     data
//   })
// }

// // 查询医生 所属商户
// export function bxMfMerchant(data) {
//   return request({
//     url: '/bxDockor/bxMfMerchant',
//     method: 'post',
//     data
//   })
// }


// // 查询医生 所属商户 用户人数
// export function bxCountByDockor(data) {
//   return request({
//     url: '/bxDockor/bxCountByDockor',
//     method: 'post',
//     data
//   })
// }

// // 查询医生 二维码
// export function createDockorTitket(data) {
//   return request({
//     url: '/bxDockor/createDockorTitket',
//     method: 'post',
//     data
//   })
// }

// // 商户列表
// export function bxMerchantQueryAll(data) {
//   return request({
//     url: '/bxMerchant/bxMerchantQueryAll',
//     method: 'post',
//     data
//   })
// }

// // 商户管理员列表
// export function merchantAdminQueryAll(data) {
//   return request({
//     url: '/bxMerchantAdmin/merchantAdminQueryAll',
//     method: 'post',
//     data
//   })
// }

// // 商户管理员列表 编辑
// export function merchantAdminUpdate(data) {
//   return request({
//     url: '/bxMerchantAdmin/merchantAdminUpdate',
//     method: 'post',
//     data
//   })
// }

// // 商户管理员列表 删除
// export function merchantAdminDelete(data) {
//   return request({
//     url: '/bxMerchantAdmin/merchantAdminDelete',
//     method: 'post',
//     data
//   })
// }

// // 商户管理员列表 新增
// export function merchantAdminAdd (data) {
//   return request({
//     url: '/bxMerchantAdmin/merchantAdminAdd',
//     method: 'post',
//     data
//   })
// }


// // 查询公众号菜单
// export function weixinMenQueryAll(data) {
// 	return request({
// 		url: 'bxtWeixinMenu/weixinMenQueryAll',
// 		method: 'post',
// 		data
// 	})
// }

// //新增公众号菜单
// export function weixinMenu(data) {
// 	return request({
// 		url: 'bxtWeixinMenu/weixinMenu',
// 		method: 'post',
// 		data
// 	})
// }
// //查询关键字管理
// export function weixinKeywordQueryAll(data) {
// 	return request({
// 		url: 'tWeixinKeyword/weixinKeywordQueryAll',
// 		method: 'post',
// 		data
// 	})
// }

// //新增关键字管理
// export function weixinKeywordAdd(data) {
// 	return request({
// 		url: 'tWeixinKeyword/weixinKeywordAdd',
// 		method: 'post',
// 		data
// 	})
// }

// //修改关键字管理
// export function weixinKeywordUpdate(data) {
// 	return request({
// 		url: 'tWeixinKeyword/weixinKeywordUpdate',
// 		method: 'post',
// 		data
// 	})
// }

// //删除关键字管理
// export function weixinKeywordDelete(data) {
// 	return request({
// 		url: 'tWeixinKeyword/weixinKeywordDelete',
// 		method: 'post',
// 		data
// 	})
// }


// //查询菜单树结构
// export function getMean(data) {
//   return request({
//     url: 'BxOrganization/getMean',
//     method: 'post',
//     data
//   })
// }

// //查询菜单树结构
// export function ruleSet(data) {
//   return request({
//     url: 'BxOrganization/ruleSet',
//     method: 'post',
//     data
//   })
// }



// //新增角色权限
// export function addRule(data) {
//   return request({
//     url: 'BxOrganization/addRule',
//     method: 'post',
//     data
//   })
// }

// //查询角色列表
// export function selectRule(data) {
//   return request({
//     url: 'BxOrganization/selectBxRule',
//     method: 'post',
//     data
//   })
// }

// //编辑角色
// export function updateRule(data) {
//   return request({
//     url: 'BxOrganization/updateRule',
//     method: 'post',
//     data
//   })
// }

// //查询用户权限表
// export function findUser(data) {
//   return request({
//     url: '/bxSystemUser/bxFindUser',
//     method: 'post',
//     data
//   })
// }

// //新增用户权限
// export function addUser(data) {
//   return request({
//     url: '/bxSystemUser/bxAddUser',
//     method: 'post',
//     data
//   })
// }

// //编辑用户权限
// export function editUser(data) {
//   return request({
//     url: '/bxSystemUser/bxEditUser',
//     method: 'post',
//     data
//   })
// }

// //删除用户
// export function delUser(data) {
//   return request({
//     url: '/bxSystemUser/bxDelUser',
//     method: 'post',
//     data
//   })
// }


// //商户列表 编辑查询
// export function bxMerchantQueryByMerchantId(data) {
//   return request({
//     url: '/bxMerchant/bxMerchantQueryByMerchantId',
//     method: 'post',
//     data
//   })
// }
// //商户列表 编辑
// export function bxMerchantUpdate(data) {
//   return request({
//     url: '/bxMerchant/bxMerchantUpdate',
//     method: 'post',
//     data
//   })
// }
// //商户列表 删除
// export function bxMerchantDelete(data) {
//   return request({
//     url: '/bxMerchant/bxMerchantDelete',
//     method: 'post',
//     data
//   })
// }
// //商户列表 省市区
// export function regionQueryAll(data) {
//   return request({
//     url: '/region/regionQueryAll',
//     method: 'post',
//     data
//   })
// }

// //代理商 用户列表
// export function bxMchDockor(data) {
//   return request({
//     url: '/bxDockor/bxMchDockor',
//     method: 'post',
//     data
//   })
// }

// //代理商 用户列表 详情
// export function bxEidtMchDockor(data) {
//   return request({
//     url: '/bxDockor/bxEidtMchDockor',
//     method: 'post',
//     data
//   })
// }

// ------新数据-------
//用户管理
export function findDcUser(data) {
  return request({
    url: '/systemUser/findDcUser',
    method: 'post',
    data
  })
}

//查询的早中晚餐信息列表
export function queryDcMeals(data) {
  return request({
    url: '/dcMeals/queryDcMeals',
    method: 'post',
    data
  })
}

//批量新增修改周早中晚餐信息
export function addBatchDcMeals(data) {
  return request({
    url: '/dcMeals/addBatchDcMeals',
    method: 'post',
    data
  })
}

//编辑信息
export function queryTeamDcMeals(data) {
  return request({
    url: '/dcMeals/queryTeamDcMeals',
    method: 'post',
    data
  })
}


//删除日期的早中晚餐信息
export function delDcMeals(data) {
  return request({
    url: '/dcMeals/delDcMeals',
    method: 'post',
    data
  })
}

//查询菜品信息
export function queryDishes(data) {
  return request({
    url: '/dcDishes/queryDishes',
    method: 'post',
    data
  })
}

//审核订单列表
export function queryCheckDcOrderPay(data) {
  return request({
    url: '/dcOrderPay/queryCheckDcOrderPay',
    method: 'post',
    data
  })
}

//审核订单列表审核操作
export function checkDcOrderOvertime(data) {
  return request({
    url: '/dcOrderPay/checkDcOrderOvertime',
    method: 'post',
    data
  })
}

//新增临时报餐
export function tempInsertBatch(data) {
  return request({
    url: '/dcUserMeals/tempInsertBatch',
    method: 'post',
    data
  })
}

//订餐列表
export function queryPCDcOrderPay(data) {
  return request({
    url: '/dcOrderPay/queryPCDcOrderPay',
    method: 'post',
    data
  })
}

//订餐部门列表
export function selectDcDept(data) {
  return request({
    url: '/organization/selectDcDept',
    method: 'post',
    data
  })
}

//订餐部门列表下人员
export function findDeptUser(data) {
  return request({
    url: '/systemUser/findDeptUser',
    method: 'post',
    data
  })
}

//订餐部门批量保存
export function updateDeptDcUserMeals(data) {
  return request({
    url: '/dcUserMeals/updateDeptDcUserMeals',
    method: 'post',
    data
  })
}

//测试富文本 添加公告
export function addPxNotice(data) {
  return request({
    url: '/pxNotice/addPxNotice',
    method: 'post',
    data
  })
}

//测试富文本反现
export function queryPxNotice(data) {
  return request({
    url: '/pxNotice/queryPCPxNotice',
    method: 'post',
    data
  })
}

//编辑富文本
export function updatePxNotice(data) {
  return request({
    url: '/pxNotice/updatePxNotice',
    method: 'post',
    data
  })
}

//公告列表
export function queryPxNoticePage(data) {
  return request({
    url: '/pxNotice/queryPxNoticePage',
    method: 'post',
    data
  })
}


//公告列表删除
export function delPxNotice(data) {
  return request({
    url: '/pxNotice/delPxNotice',
    method: 'post',
    data
  })
}

//视频分类列表
export function queryPxCatPage(data) {
  return request({
    url: '/pxCat/queryPxCatPage',
    method: 'post',
    data
  })
}
//新增视频分类
export function addPxCat(data) {
  return request({
    url: '/pxCat/addPxCat',
    method: 'post',
    data
  })
}
//编辑新增视频分类
export function updatePxCat(data) {
  return request({
    url: '/pxCat/updatePxCat',
    method: 'post',
    data
  })
}

//删除新增视频分类
export function delPxCat(data) {
  return request({
    url: '/pxCat/delPxCat',
    method: 'post',
    data
  })
}


//视频列表
export function queryPxVideoPage(data) {
  return request({
    url: '/pxVideo/queryPxVideoPage',
    method: 'post',
    data
  })
}

//上下架视频
export function updatePxVideo(data) {
  return request({
    url: '/pxVideo/updatePxVideo',
    method: 'post',
    data
  })
}



//删除视频
export function delPxVideo(data) {
  return request({
    url: '/pxVideo/delPxVideo',
    method: 'post',
    data
  })
}

//视频分类筛选列表
export function queryPxCat(data) {
  return request({
    url: '/pxCat/queryPxCat',
    method: 'post',
    data
  })
}

//视频封面图片
export function mp4ToJpg(data) {
  return request({
    url: '/pxVideo/mp4ToJpg',
    method: 'post',
    data
  })
}

//视频新增
export function addPxVideo(data) {
  return request({
    url: '/pxVideo/addPxVideo',
    method: 'post',
    data
  })
}

//视频详情
export function queryPxVideo(data) {
  return request({
    url: '/pxVideo/queryPxVideo',
    method: 'post',
    data
  })
}


//报餐人员列表
export function queryDcUserInfoPage(data) {
  return request({
    url: '/dcOrderPay/queryDcUserInfoPage',
    method: 'post',
    data
  })
}

//新增菜品
export function addDishes(data) {
  return request({
    url: '/dcDishes/addDishes',
    method: 'post',
    data
  })
}
//更新菜品
export function updateDishes(data) {
  return request({
    url: '/dcDishes/updateDishes',
    method: 'post',
    data
  })
}
//查询菜品
export function queryAllPage(data) {
  return request({
    url: '/dcDishes/queryAllPage',
    method: 'post',
    data
  })
}
//订餐列表消餐

export function userInfoCancel(data) {
  return request({
    url: '/dcOrderPay/userInfoCancel',
    method: 'post',
    data
  })
} 

//订餐列表部门

export function selectMinDcDept(data) {
  return request({
    url: '/organization/selectMinDcDept',
    method: 'post',
    data
  })
} 


//订餐列表角色

export function selectRule(data) {
  return request({
    url: '/organization/selectRule',
    method: 'post',
    data
  })
} 

//添加用户提交
export function addBxUser(data) {
  return request({
    url: '/userManager/addBxUser',
    method: 'post',
    data
  })
} 

//删除用户
export function delBxUser(data) {
  return request({
    url: '/userManager/delBxUser',
    method: 'post',
    data
  })
} 

//查询未用餐人员
export function queryNoUser(data) {
  return request({
    url: '/dcQc/queryNoUser',
    method: 'post',
    data
  })
} 
//导出部门正在学习信息
export function exportDeptStduyUserInfo(data) {
  return request({
    url: '/pxUserInfo/exportDeptStduyUserInfo',
    method: 'post',
    data
  })
} 










































